import React from "react";
import BrakeImg from "../assets/brakes.jpg";
import ServiceImg from "../assets/service.jpg";
import ClutchImg from "../assets/clutch.jpg";
import DiagnosticsImg from "../assets/diagnostics.jpg";

const Card = () => {
  return (
    <div className="flex flex-auto flex-row flex-wrap md:flex-nowrap  mx-auto w-full px-5 nd:px-20 mt-10 sm:mt-10">
      <div className="sm:w-full md:w-1/2 mb-2 md:mb-0 bg-gradient-to-b from-[#f9d071] to-[#ffedc2] border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-2">
        <a href="#">
          <img className="rounded-t-lg" src={BrakeImg} alt="" />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className="mb-2 text-2xl font-bold text-center tracking-tight text-gray-900 dark:text-white">
              BRAKES
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-700 text-center dark:text-gray-400">
            From a simple brake pad replacement to pads, discs and calipers. We
            can repair or replace all brake components.
          </p>
        </div>
      </div>
      <div className="sm:w-full md:w-1/2 mb-2 md:mb-0 bg-gradient-to-b from-[#f9d071] to-[#ffedc2] border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-2">
        <a href="#">
          <img className="rounded-t-lg" src={ServiceImg} alt="" />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className="mb-2 text-2xl font-bold text-center tracking-tight text-gray-900 dark:text-white">
              FULL SERVICE
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-700 text-center dark:text-gray-400">
            Due a service? We only use genuine, brand new parts for all of our
            services. You can be in and out in as little as an hour.
          </p>
        </div>
      </div>
      <div className="sm:w-full md:w-1/2 mb-2 md:mb-0 bg-gradient-to-b from-[#f9d071] to-[#ffedc2] border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-2">
        <a href="#">
          <img className="rounded-t-lg" src={ClutchImg} alt="" />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className="mb-2 text-2xl font-bold text-center tracking-tight text-gray-900 dark:text-white">
              CLUTCH SPECIALISTS
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-700 text-center dark:text-gray-400">
            We have 30 years' experience replacing and repairing clutches. If
            you need a replacement, or just want a quote, get in touch.
          </p>
        </div>
      </div>
      <div className="sm:w-full md:w-1/2 mb-2 md:mb-0 bg-gradient-to-b from-[#f9d071] to-[#ffedc2] border border-gray-200 rounded-lg shadow dark:bg-gray-800 dark:border-gray-700 mx-2">
        <a href="#">
          <img className="rounded-t-lg" src={DiagnosticsImg} alt="" />
        </a>
        <div className="p-5">
          <a href="#">
            <h5 className="mb-2 text-2xl font-bold text-center tracking-tight text-gray-900 dark:text-white">
              FAULT DIAGNOSTICS
            </h5>
          </a>
          <p className="mb-3 font-normal text-gray-700 text-center dark:text-gray-400">
            Car making a funny noise? Bring it to us and we can diagnose the
            issue for you. We use top-of-the-range diagnostic tools.
          </p>
        </div>
      </div>
    </div>
  );
};

export default Card;
