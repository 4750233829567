import Logo from "../assets/logo.png";

const Navbar = () => {
  return (
    <div className="flex justify-between item-center h-24 sm:min-h-48 max-w-[1240px] mx-auto px-4 text-white">
      <img src={Logo} className="w-full h-48 md:w-96" alt="logo"></img>
      <ul className="hidden md:flex">
        <li className="p-10">
          <a href="tel:07427861244">
            <button className="bg-gradient-to-b from-[#f9d071] to-[#ffedc2] w-[500px] text-3xl rounded-md font-extrabold my-0  py-5 text-[#000000] ">
              CALL US ON 07427 861244
            </button>
          </a>
        </li>
      </ul>
    </div>
  );
};

export default Navbar;
