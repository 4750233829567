import React from "react";
import { ReactTyped } from "react-typed";
import HeroImg from "../assets/hero.png";

const Hero = () => {
  return (
    <div className="text-white z-0">
      <div className="w-full md:py-28 py-0 px-2">
        <div className="max-w-[1440px] mx-auto grid md:grid-cols-2 align-middle">
          <div className="mx-auto py-20">
            <p className="text-[#f9d071] font-bold text-center md:text-left">
              QUALITY VEHICLE REPAIR, SERVICE AND RECOVERY
            </p>
            <div className="flex justify-center items-center text-center md:text-left">
              <h1 className="lg:text-7xl md:text-5xl sm:text-4xl text-4xl font-bold md:py-1 md:text-left sm:text-center">
                WE CAN HELP WITH
                <p className="lg:hidden md:hidden sm:block">{"\n"}</p>
                <ReactTyped
                  className="lg:text-7xl md:text-5xl sm:text-4xl text-5xl text-[#f9d071] font-bold md:pl-4 pl-2 sm:pl-0"
                  strings={[
                    "DIAGNOSTICS",
                    "SERVICES",
                    "MOT's",
                    "CLUTCHES",
                    "BRAKES",
                    "RECOVERY",
                  ]}
                  typeSpeed={180}
                  backspeed={200}
                  loop
                />
              </h1>
            </div>
          </div>
          <div className="md:hidden sm:block mx-auto -mt-10 mb-10">
            <a href="tel:07427861244">
              <button className="bg-gradient-to-b from-[#f9d071] to-[#ffedc2] w-[350px] text-2xl rounded-md font-extrabold my-0  py-5 text-[#000000] ">
                CALL US ON 07427 861244
              </button>
            </a>
          </div>
          <img
            src={HeroImg}
            className="lg:scale-150 md:scale-120 transform hover:rotate-3 transition duration-300"
            alt="Hero"
          ></img>
        </div>
      </div>
    </div>
  );
};

export default Hero;
